
::v-deep p {
    margin: 0;
}
.search-text-ellipsis {
    &::v-deep em {
        color: #f04142;
        font-style: normal;
    }
}
.graphic-container {
    position: relative;
    /*overflow: hidden;*/
    display: flex;
    /*align-items: center;*/
    flex-direction: column;
    height: calc(100vh - 100px);
    margin-right: -20px;
    .tab-wrapper {
        margin: 27px 0 17px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #DFDCFF;
        }
        .tab-item {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 22px;
            color: #574DED;
            border-radius: 4px;
            font-size: 16px;
            margin-right: 20px;
            transition: all .3s;
            &.current, &:hover {
                color: #fff;
                background: #574DED;
            }
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 40px;
            }
        }
    }
    .graphic-box {
        flex: 1;
        height: 1%;
        display: flex;
        align-items: center;
    }
    .graphic-wrapper {
        /*width: calc(100% - 552px);*/
        height: 100%;
        display: flex;
        width: 1%;
        flex: 1;
        .caption-wrapper {
            width: 35%;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
            .caption-tabs {
                background: #fff;
                text-align: center;
                height: 52px;
                line-height: 52px;
                position: relative;
                border-radius: 10px 10px 0 0;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    background: #EEE;
                }
                .tab-item {
                    display: inline-block;
                    margin-right: 60px;
                    position: relative;
                    cursor: pointer;
                    transition: all .3s;
                    &:last-child {
                        margin-right: 0;
                    }
                    &.current {
                        color: #1E33E4;
                        &:after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            background: #1E33E4;
                        }
                    }
                    &:hover {
                        color: #1E33E4;
                    }
                }
            }
            .caption-item {
                background: #fff;
                /*margin-top: 16px;*/
                display: none;
                height: 1%;
                flex: 1;
                flex-direction: column;
                border-radius: 0 0 10px 10px;
                &.current {
                    display: flex;
                }
                .caption-list {
                    height: 1%;
                    flex: 1;
                    margin: 10px 0;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .item {
                        padding: 16px;
                        border-bottom: 1px solid #EAEAEA;
                        .caption {
                            margin-bottom: 10px;
                            color: #333;
                            &:hover {
                                color: #1E33E3;
                            }
                        }
                        .caption-btn {
                            text-align: right;
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
            &.draft-wrapper {
                background: #fff;
                .draft-top {
                    height: 40px;
                    line-height: 40px;
                    background: #E5E8FB;
                    display: flex;
                    border-radius: 10px 10px 0 0;
                    & > span {
                        width: 1%;
                        flex: 1;
                        padding-left: 20px;
                    }
                    .el-button {
                        width: 40px;
                        height: 40px;
                    }
                }
                .draft-list {
                    height: 1%;
                    flex: 1;
                    margin: 10px 0;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .draft-item {
                        padding: 16px 20px;
                        border-bottom: 1px solid #EAEAEA;
                        .title {
                            font-size: 16px;
                            color: #333;
                        }
                        .content {
                            color: #999;
                            margin-top: 10px;
                            max-height: 38px;
                            ::v-deep img {
                                display: none;
                            }
                            ::v-deep iframe {
                                display: none;
                            }
                            ::v-deep * {
                                font-size: 14px;
                                font-weight: normal!important;
                                text-decoration-line: none!important;
                                background-color: transparent!important;
                                color: #999;
                                margin: 0;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                            }
                        }
                        .btn-group {
                            margin-top: 10px;
                            text-align: right;
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        .publish-wrapper {
            background: #fff;
            margin-left: 40px;
            width: 1%;
            flex: 1;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
            border-radius: 10px;
            .create-title {
                display: flex;
                .text {
                    line-height: 32px;
                }
                .el-input {
                    margin-left: 10px;
                    width: 1%;
                    flex: 1;
                }
            }
            #article_content {
                margin-top: 12px;
                height: 1%;
                flex: 1;
                display: flex;
                flex-direction: column;
                z-index: 1;
                ::v-deep .w-e-toolbar {
                    padding: 0;
                    border: none!important;
                    background-color: initial!important;
                }
                ::v-deep .w-e-text-container {
                    border: none!important;
                    height: 1%;
                    flex: 1;
                    .w-e-text {
                        padding: 0;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
    .hot-search-wrapper {
        /*margin-left: 70px;*/
        /*position: absolute;*/
        /*top: 20px;*/
        //right: -360px;
        /*right: 0;*/
        /*bottom: 20px;*/
        transition: all .3s;
        position: relative;
        height: 100%;
        .hot-tab-wrapper {
            position: absolute;
            width: 30px;
            left: -30px;
            top: 28px;
            text-align: center;
            .left-icon {
                display: inline-block;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background: #E5E8FB;
                color: #666;
                cursor: pointer;
                &.current {
                    transform: rotate(180deg);
                }
            }
            .hot-tabs {
                background: #fff;
                line-height: 30px;
                writing-mode: vertical-lr;
                height: 90px;
                margin-bottom: 5px;
                cursor: pointer;
                &.current {
                    color: #fff;
                    background: #2338E6;
                }
            }
        }
        .hot-search-content {
            width: 360px;
            height: 100%;
            display: none;
            transition: all .3s;
            background: #fff;
            padding-top: 28px;
            box-sizing: border-box;
            border-radius: 10px 0 0 10px;
            box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
            &.current {
                display: block;
            }
            .hot-search-prompt {
                color: #999;
                font-size: 12px;
                background: #E5E8FB;
                height: 30px;
                line-height: 30px;
                padding-left: 16px;
            }
            .hot-search-list {
                height: calc(100% - 30px);
                display: flex;
                flex-direction: column;
                background: #fff;
                border-radius: 0 0 0 10px;
                .list-wrapper {
                    height: 1%;
                    flex: 1;
                    padding: 12px 0;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }
                .item {
                    display: flex;
                    align-items: center;
                    line-height: 36px;
                    &.item-top {
                        height: 44px;
                        line-height: 44px;
                        border-bottom: 1px solid #EAEAEA;
                    }
                    &.item-hotspot {
                        height: 44px;
                        padding: 0 16px;
                        border-bottom: 1px solid #EAEAEA;
                        .el-button {
                            margin-left: 10px;
                        }
                    }
                    .item-left {
                        width: 1%;
                        flex: 1;
                        padding-left: 16px;
                        display: flex;
                        align-items: center;
                        .item-index {
                            width: 20px;
                            height: 20px;
                            background: #8EB9F5;
                            border-radius: 50%;
                            color: #fff;
                            text-align: center;
                            line-height: 20px;
                        }
                        .item-cap {
                            width: 1%;
                            flex: 1;
                            margin-left: 10px;
                            cursor: pointer;
                            transition: all .3s;
                            color: #333;
                            &:hover {
                                color: #1E34E4;
                            }
                        }
                    }
                    &:nth-child(1) {
                        .item-left {
                            .item-index {
                                background: #FF0000;
                            }
                        }
                    }
                    &:nth-child(2) {
                        .item-left {
                            .item-index {
                                background: #FF8547;
                            }
                        }
                    }
                    &:nth-child(3) {
                        .item-left {
                            .item-index {
                                background: #FFAC38;
                            }
                        }
                    }
                    .item-right {
                        padding-right: 24px;
                        margin-left: 10px;
                        .item-num {
                            font-size: 12px;
                            color: #999;
                        }
                        .iconfont {
                            font-size: 12px;
                            margin-left: 3px;
                            font-weight: bold;
                            &.up-icon {
                                color: #FF3333;
                            }
                            &.down-icon {
                                color: #2338E6;
                            }
                        }
                    }
                }
            }
        }
    }
    .operate-btn {
        /*position: absolute;*/
        /*top: 50%;*/
        //transform: translateY(-50%);
        /*left: calc(100% - 536px);*/
        margin: 0 80px 0 20px;
        .operate-item {
            line-height: 36px;
            background: #fff;
            margin-top: 10px;
            width: 102px;
            padding-left: 12px;
            box-sizing: border-box;
            cursor: pointer;
            transition: all .3s;
            border-radius: 2px;
            .iconfont {
                font-size: 16px;
                margin-right: 6px;
                vertical-align: bottom;
            }
            &.current, &:hover {
                color: #fff;
                background: #2338E6;
            }
        }
    }
}
.keys-form {
    padding: 16px;
    border-bottom: 1px solid #EAEAEA;
    .prompt-info {
        color: #999;
        margin-bottom: 20px;
    }
    .el-form-item {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.hot-caption {
    padding: 16px;
    border-bottom: 1px solid #EAEAEA;
    .hot-caption-search {
        display: flex;
        .text {
            line-height: 32px;
        }
        .el-input {
            width: 1%;
            flex: 1;
            margin-left: 10px;
        }
    }
    .hot-cap {
        margin-top: 16px;
    }
}
.draftPopover {
    .draft-box {
        padding: 4px;
        .draft-btn {
            color: #333;
            cursor: pointer;
            padding: 10px 18px;
            position: relative;
            border-bottom: 1px solid #EAEAEA;
            border-radius: 2px;
            &:hover {
                color: #2338E6;
            }
            &:last-child {
                border-bottom: none;
            }
            &.current {
                color: #fff;
                background: #2338E6;
            }
            &.publish-btn {
                display: flex;
                align-items: center;
                img {
                    max-width: 24px;
                    max-height: 24px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.detection-dialog {
    ::v-deep .el-dialog__footer {
        text-align: center;
    }
    .detection-content {
        .item {
            font-size: 16px;
            display: flex;
            margin-top: 10px;
            .item-left {
                width: 1%;
                flex: 1;
            }
            .item-right {
                width: 150px;
                text-align: left;
                .iconfont {
                    margin-right: 10px;
                    &.green {
                        color: #00C83C;
                    }
                    &.red {
                        color: #ff0000;
                    }
                }
            }
            .turn-round {
                width: 16px;
                height: 16px;
                display: inline-block;
                animation: turnRound 1s linear infinite;
                margin-right: 10px;
            }
        }
    }
}
.detection-detail-dialog {
    ::v-deep .el-dialog__body {
        padding: 20px 0;
        border-top: 1px solid #EAEAEA;
        .detection-detail-wrapper {
            .detail-intro {
                padding: 0 20px;
                .intro-item {
                    margin-top: 16px;
                    &:first-child {
                        margin-top: 0;
                    }
                    span {
                        margin-right: 20px;
                        &.item-right {
                            strong {
                                color: #FF0000;
                                margin-right: 20px;
                            }
                            .blue {
                                color: #1E33E3;
                            }
                        }
                    }
                }
            }
            .monitor-tabs {
                margin-top: 20px;
                background: #F5F5F5;
                text-align: center;
                .tab-item {
                    display: inline-block;
                    line-height: 36px;
                    padding: 0 24px;
                    cursor: pointer;
                    &.current {
                        color: #fff;
                        background: #2338E6;
                    }
                }
            }
            .monitor-content {
                display: none;
                height: 260px;
                margin: 20px 20px 0;
                border: 1px solid #EAEAEA;
                padding: 20px;
                overflow: auto;
                &.current {
                    display: block;
                }
                .monitor-list {
                    .item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                        .item-left {
                            .iconfont {
                                margin-right: 10px;
                            }
                            &.blue {
                                font-size: 16px;
                            }
                        }
                        .blue {
                            color: #1E33E3;
                        }
                        .green {
                            color: #00C83C;
                        }
                        .red {
                            color: #FF0000;
                        }
                    }
                }
                .line {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
.correctPopover {
    .correct-box {
        display: flex;
        flex-direction: column;
        min-height: 300px;
        max-height: 500px;
        .correct-title {
            padding-left: 20px;
            color: #666;
            background: #E5E8FB;
            line-height: 40px;
            position: relative;
            .el-button {
                position: absolute;
                right: 0;
                width: 40px;
            }
        }
        .correct-content {
            padding: 20px;
            height: 1%;
            flex: 1;
            overflow: auto;
            margin-bottom: 40px;
        }
        .correct-bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            line-height: 40px;
            color: #666;
            background: #E5E8FB;
            padding-left: 20px;
            strong {
                color: #ff851a;
            }
        }
        ::v-deep .replace-content {
            position: relative;
            display: inline-block;
            &:hover .replace-dialog {
                display: block;
            }
            .text {
                background: #bad7fb;
                z-index: 6;
            }
            .replace-dialog {
                z-index: 7;
                position: absolute;
                top: 25px;
                left: 50%;
                min-width: 12px;
                white-space: nowrap;
                background: #303133;
                color: #fff;
                border-radius: 4px;
                padding: 10px;
                transform: translateX(-50%);
                font-size: 12px;
                line-height: 1.2;
                display: none;
                &:before {
                    content: '';
                    position: absolute;
                    top: -6px;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px;
                    border-top-width: 0;
                    border-bottom-color: #303133;
                    transform: translateX(-50%);
                    background: #ff0000;
                }
            }
        }
    }
}
@keyframes turnRound {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.preview-shadow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 9;
}
.preview-dialog {
    width: 346px;
    height: 693px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        z-index: 9;
        background: #fff;
        border-radius: 30px;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent url("../../../assets/img/image/phone-bg.png") no-repeat;
        z-index: 9;
    }
    .preview-dialog-wrapper {
        position: absolute;
        top: 4px;
        left: 10px;
        right: 10px;
        bottom: 4px;
        border-radius: 75px;
        padding: 41px 11px 15px 13px;
        z-index: 10;
        .close-preview {
            position: absolute;
            right: -50px;
            top: 0;
            font-size: 18px;
            color: #fff;
            cursor: pointer;
        }
        .preview-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .preview-title {
                margin: 20px 0;
                padding: 0 20px;
                font-size: 18px;
                color: #333;
                font-weight: bold;
            }
            .preview-content {
                flex: 1;
                height: 1%;
                ::v-deep img {
                    max-width: 100%;
                }
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                ::v-deep iframe {
                    max-width: 100%;
                }
                ::v-deep pre {
                    overflow: auto;
                }
                ::v-deep a {
                    color: #1E33E3;
                }
                ::v-deep blockquote {
                    display: block;
                    border-left: 8px solid #d0e5f2;
                    padding: 5px 10px;
                    margin: 10px 0;
                    line-height: 1.4;
                    font-size: 100%;
                    background-color: #f1f1f1;
                }
                ::v-deep code {
                    background-color: #f1f1f1;
                    border-radius: 3px;
                    padding: 3px 5px;
                    margin: 0 3px;
                }
                .content {
                    padding: 0 20px 20px;
                }
            }
        }
    }
}
